<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
  >
  <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!hiddenBtn"
        color="primary"
        rounded
        dark
        small
        @click="dialog=true"
      >
        Update
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
          <span class="headline my-4">Update realty</span>
      </v-card-title>
      <v-form ref="form">
        <v-card-text class="pt-0 mt-0">
            <v-container>
              <v-row>
                  <v-col
                      cols="12"
                  >
                      <v-text-field
                          label="Name*"
                          v-model="data.name"
                          outlined
                          :error="data.name == '' && showError"
                          :rules="[(v) => !!v || 'Field is required']"
                          required
                          class="mb-0"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          label="Address*"
                          v-model="data.address"
                          :error="data.address == '' && showError"
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          required
                          class="mb-0"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6" >
                      <v-text-field
                          label="Contact number*"
                          v-model="data.contact_number"
                          :error="data.contact_number == '' && showError"
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          required
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          v-model="data.email_address"
                          label="Email address*"
                          outlined
                          required
                          class="mb-0"
                          :rules="emailRules"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="mb-6">
                      <v-select
                          label="Lead broker"
                          v-model="data.lead_broker_id"
                          :items="brokerList"
                          :item-text="'name'"
                          :item-value="'id'"
                          hide-details="auto"
                          :error="(!data.lead_broker_id || data.lead_broker_id == '') && showError"
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          required
                          ></v-select>
                  </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
        </v-card-text>
      </v-form>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click="close"
          >
              Close
          </v-btn>
          <v-btn
              color="primary"
              @click="handleUpdate"
              :loading="loader"
              :disabled="loader"
          >
              Save
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: 'UpdateRealty',
    props: {
      show: Boolean,
      item: {
        type: Object
      },
      hiddenBtn: {
        type: Boolean,
        default: () => true
      },
    },
    data: () => ({
      showError: false,
      menu: [],
      data: {
        name: "",
        address: "",
        email_address: "",
        contact_number: "",
        lead_broker_id: null
      },
      brokerList: [],
      loader: false,
      dialog: false,
      emailRules: [
        value => !!value || 'E-mail is required',
        value => /.+@.+/.test(value) || 'E-mail must be valid',
      ],
    }),
    mounted() {
      this.getBrokersList();
    },
    methods: {
      ...mapActions(["updateRealty", "getBrokers"]),
      getBrokersList() {
        this.getBrokers()
          .then((response) => {
              this.brokerList = response.data;
          })
          .catch((error) => {
              console.log(error);
          })
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      handleUpdate() {
        this.loader = true
        let isValid = this.$refs.form.validate()
        if (!isValid) {
            this.loader = false
            return
        }
        
        this.updateRealty({id: this.data.id, formData: this.data })
          .then((response) => {
            console.log('response', response)
            this.loader = false;
            this.update()
          })
          .catch((error) => {
            console.log(error);
            this.loader = false;
          })
      },
      clearData() {
        this.data.name = "";
        this.loader = false
      },
      onError() {
        this.loader = false
      },
      close() {
        this.clearData()
        this.$emit('close')
      },
      update() {
        this.clearData()
        this.$emit('update')
      }
    },
    watch: {
      show: {
        handler: function (newValue) {
          if(newValue) {
            Object.assign(this.data, {
              ...this.item,
              lead_broker_id: this.item.lead_broker?.id
            });
          }
          this.dialog = newValue;
        },
      },

      dialog(newVal) {
        if (!newVal) {
          this.close();
        }
      },
    },
}
</script>
<style scoped lang="scss">
.col {
    padding-bottom: 0;
    padding-top: 0;
}
.image-box {
  position: relative;
  .v-btn--icon {
    top: -13px;
    right: 0px;
    i {
      background: #fff;
      border-radius: 100%;
    }
  }
}
</style>
