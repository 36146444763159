<template>
    <v-row>
           <v-btn
            color="primary"
            dark
            x-large
            @click="dialog=true"
        >
            Add
        </v-btn>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline my-4">Add realty</span>
                </v-card-title>
                <v-form ref="form">
                <v-card-text class="pt-0 mt-0">
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    label="Name*"
                                    v-model="data.name"
                                    outlined
                                    :error="data.name == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    required
                                    class="mb-0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="Address*"
                                    v-model="data.address"
                                    :error="data.address == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    outlined
                                    required
                                    class="mb-0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" >
                                <v-text-field
                                    label="Contact number*"
                                    v-model="data.contact_number"
                                    :error="data.contact_number == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    outlined
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="data.email_address"
                                    label="Email address*"
                                    outlined
                                    required
                                    class="mb-0"
                                    :rules="emailRules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="mb-6">
                                <v-select
                                    label="Lead broker"
                                    v-model="data.lead_broker_id"
                                    :items="brokerList"
                                    :item-text="'name'"
                                    :item-value="'id'"
                                    hide-details="auto"
                                    :error="(!data.lead_broker_id || data.lead_broker_id == '') && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    outlined
                                    ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeModal"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="handleAddRealty"
                        :loading="loader"
                        :disabled="loader"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

    export default {
        name: 'AddRealtyModal',
        data: () => ({
            showError: false,
            menu: [],
            data: {
                name: "",
                address: "",
                contact_number: "",
                email_address: ""
            },
            brokerList: [],
            loader: false,
            dialog: false,
            image: null,
            emailRules: [
                value => !!value || 'E-mail is required',
                value => /.+@.+/.test(value) || 'E-mail must be valid',
            ],
        }),
        mounted() {
            this.clearData();
            this.getBrokersList();
        },
        computed: {
            
        },
        watch: {
            dialog(newVal, odlVal) {
                this.$nextTick()
                    .then(_ => {
                        this.clearData()
                        this.$refs.form.reset()
                    })
            },
            errors: {
                deep: true,
                handler(newVal, oldVal) {
                    this.onError()
                }
            }
        },
        methods: {
            ...mapActions(["addRealty", "updateRealty", "getBrokers"]),
            getBrokersList() {
              this.getBrokers()
                .then((response) => {
                    this.brokerList = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
            },
            handleAddRealty() {
                this.loader = true
                let isValid = this.$refs.form.validate()
                if (!isValid) {
                    this.loader = false
                    return
                }
                
                this.addRealty(this.data)
                    .then((response) => {
                        this.loader = false;
                        this.update();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader = false;
                    })
            },
            closeModal() {
                this.dialog = false;
                this.clearData();
            },
            clearData() {
                this.data.name = "";
                this.loader = false
            },
            onError() {
                this.loader = false
            },
            update() {
                this.closeModal();
                this.$emit('update');
            }
        }
    }
</script>
<style scoped>
    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
</style>
