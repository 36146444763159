<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
          class="ml-2"
          small
        >
          View
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <div>
            <span class="headline my-4">{{ item.name }}</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-phone</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Contact Number</v-list-item-subtitle>
              <v-list-item-title>{{ item.contact_number }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-email</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Email address</v-list-item-subtitle>
              <v-list-item-title>{{ item.email_address }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-map-marker</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Address</v-list-item-subtitle>
              <v-list-item-title>{{ item.address }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Lead Broker</v-list-item-subtitle>
              <v-list-item-title>{{ item.lead_broker?.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>

export default {
  name: 'ViewRealty',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    broker: null
  }),
  methods: {},
  watch: {
    dialog(val) {
       if(val) {
        this.getBrokerUser()
       }
    },
},
}
</script>
<style scoped lang="scss">
.col {
    padding-bottom: 0;
    padding-top: 0;
}
a {
  text-decoration: none;
}
.flex-wrap {
  flex-wrap: wrap;
}
.v-list-item__title {
  white-space: normal;
}
svg {
  height: 32px;
  margin-top: 4px;
}
</style>
